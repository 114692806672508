<template>
  <!-- 植物记录 -->
  <div class="cont" @scroll="scrollBottom($event)">
    <div class="record">
      <TopBanner @toggle="showMask = !showMask"/>
      <template
        v-if="
          user_section.user_id != null &&
          user_section.user_id != undefined &&
          user_section.user_id != ''
        "
      >
        <div class="page2">
          <div class="dom1">{{ user_section.title }}</div>
          <div
            class="dom2"
            @click="
              $router.push({
                path: '/html/app/homepage',
                query: { id: user_section.user_id },
              })
            "
          >
            <div class="add">查看更多</div>
            <van-image
              round
              width="40"
              height="40"
              :src="user_section.img"
              class="img"
            />
            <div class="txt">{{ user_section.nickName | maxLen }}</div>
          </div>
          <div class="dom3">
            <pre>{{ user_section.txt | upString }}</pre>
          </div>
          <div class="dom4">
            <span>浏览量：{{ user_section.llNum }}</span>
            <span>记录次数：{{ user_section.jlNum }}</span>
          </div>
        </div>
        <div class="page3">
          <div class="box" v-for="(item, index) in record_txt_li" :key="index">
            <div class="time">
              <div>{{ item.m }}</div>
              <div>{{ item.y }}</div>
            </div>
            <div class="tit"><pre>{{ item.tit }}</pre></div>
            <a v-for="(jtem, jndex) in item.imgs" :key="jndex" class="a">
              <van-image
                v-if="item.imgs.length == 1"
                :src="jtem"
                class="li_1"
                @click="open_record_imgview(jndex, item.largeImgs)"
              />
              <van-image
                v-if="item.imgs.length == 2 || item.imgs.length == 4"
                :src="jtem"
                class="li_2"
                @click="open_record_imgview(jndex, item.largeImgs)"
              />
              <van-image
                v-if="item.imgs.length == 3"
                :src="jtem"
                class="li_3"
                @click="open_record_imgview(jndex, item.largeImgs)"
              />
              <van-image
                v-if="item.imgs.length > 4 && item.imgs.length <= 9"
                :src="jtem"
                class="li_3"
                @click="open_record_imgview(jndex, item.largeImgs)"
              />
              <van-image
                v-if="item.imgs.length > 9"
                :src="jtem"
                class="li_10"
                @click="open_record_imgview(jndex, item.largeImgs)"
              />
              <div
                class="add"
                v-if="jndex == 8 && item.imgs.length > 9"
                @click="open_record_imgview(jndex, item.largeImgs)"
              >
                <div>
                  <span class="add_i">+</span>{{ item.imgs.length - 9 }}
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="page4" @click="open_app()">打开APP，给TA留言点赞</div>
      </template>
    </div>
    <TipMask :visible="showMask" @toggle="showMask = !showMask"></TipMask>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import TopBanner from "../../components/TopBanner.vue";
import TipMask from '../../components/TipMask.vue';
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  components: {
    TopBanner,
    TipMask
  },
  mounted() {
    this.appUrl.onlyMo(); //只允许移动端打开
    if (
      this.$route.query.id == null ||
      this.$route.query.id == undefined ||
      this.$route.query.id == ""
    ) {
      this.appUrl.alertErr("无权访问");
      return;
    }
    document.title = this.record_title;
    this.id_record = this.$route.query.id;
    this.getPlantInfo(this.id_record, this.page_num, this.page_size);
  },
  data() {
    return {
      showMask: false,
      record_title: "植物成长记", //页面title
      page_bool: true,
      page_num: 1,
      page_size: 20,
      id_record: null,
      url_web_detail: this.apiUrl.url_web_detail,
      down_url_iphone: this.appUrl.down_url_iphone, //Iphone下载链接
      down_url_android: this.appUrl.down_url_android, //Android下载链接
      url_iphone: "xzw://smallplants.cn/app/plant?id=" + this.$route.query.id, //Iphone唤起APP链接
      url_android: "xzw://smallplants.cn/app/plant?id=" + this.$route.query.id, //Android唤起APP链接
      user_section: {
        //用户信息
        user_id: null,
        nickName: null,
        img: null,
        title: null,
        txt: null,
        llNum: null,
        jlNum: null,
        datalist: null,
      },
      record_txt_li: [],
    };
  },
  filters: {
    upString(v) {
      let text = ``;
      for (let i = 0; i < v.split("").length; i++) {
        if (i == v.split(" ").length - 1) {
          text = `${text}${v.split(" ")[i]}`;
        } else {
          text = `${text}${v.split(" ")[i]}/n`;
        }
        // console.log(text)
        return text;
      }
    },
    maxLen(v) {
      if (v.length >= 10) {
        return v.substring(0, 9) + "...";
      } else {
        return v;
      }
    },
  },
  methods: {
    scrollBottom(e) {
      if (this.record_txt_li.length % this.page_size != 0) {
        this.page_bool = false;
      }
      if (this.page_bool) {
        if (
          e.srcElement.scrollTop + e.srcElement.offsetHeight >
          e.srcElement.scrollHeight - 50
        ) {
          this.page_bool = false;
          this.getPlantInfo(
            this.$route.query.id,
            this.page_num,
            this.page_size
          );
        }
      }
    },
    open_record_imgview(i, imgs) {
      //预览大图
      ImagePreview({
        images: imgs,
        startPosition: i,
      });
    },
    updata_list(data) {
      if (
        this.user_section.user_id == null ||
        this.user_section.user_id == undefined ||
        this.user_section.user_id == ""
      ) {
        this.user_section.user_id = data.detail.author.uid;
        this.user_section.nickName = data.detail.author.nickname;
        this.user_section.img = data.detail.author.avatar;
        this.user_section.title = data.detail.title;
        this.user_section.txt = data.detail.description;
        this.user_section.llNum = data.detail.views;
        this.user_section.jlNum = data.list.length;
      }
      for (let i = 0; i < data.list.length; i++) {
        this.record_txt_li.push({
          y: data.list[i].recordDate.split("-")[0],
          m:
            data.list[i].recordDate.split("-")[1] +
            "." +
            data.list[i].recordDate.split("-")[2],
          tit: data.list[i].content,
          imgs: [],
          largeImgs: []
        });
        for (let j = 0; j < data.list[i].images.length; j++) {
          this.record_txt_li[
            i + (this.page_num - 1) * this.page_size
          ].imgs.push(data.list[i].images[j].url);
          this.record_txt_li[
            i + (this.page_num - 1) * this.page_size
          ].largeImgs.push(data.list[i].images[j].largeUrl);
        }
      }
    },
    getPlantInfo(id, num, size) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(`${this.url_web_detail}?id=${id}&pageNum=${num}&pageSize=${size}`)
        .then((res) => {
          Toast.clear();
          if (res.data.code == 200) {
            if (
              res.data.data.list != null &&
              res.data.data.list != undefined &&
              res.data.data.list.length > 0
            ) {
              this.datalist = res.data.data;
              this.updata_list(this.datalist);
              this.page_num++;
              this.page_bool = true;
            }
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          Toast.clear();
          this.appUrl.alertErr("网络异常");
          console.log("err");
        });
    },
    open_app() {
      //下载app
      var ua = navigator.userAgent.toLowerCase();
      //系统判断
      if ((ua.indexOf('qq')>-1 && ua.indexOf('mqqbrowser')<0) ||
        ua.match(/MicroMessenger/i) == 'micromessenger') {
          // qq 或微信浏览器
        this.showMask = true
      } else if (ua.match(/iPhone/i) == "iphone") {
        //iphone
        console.log(ua.match(/iPhone/i));
        var loadTime = new Date();
        window.open(this.url_iphone);
        setTimeout(function () {
          var outTime = new Date();
          if (outTime - loadTime > 800) {
            window.open(this.down_url_iphone);
          }
        }, 1000);
      } else if (ua.match(/Android/i) == "android") {
        //android
        console.log(ua.match(/Android/i));
        loadTime = new Date();
        window.open(this.url_android);
        setTimeout(function () {
          var outTime = new Date();
          if (outTime - loadTime > 800) {
            window.open(this.down_url_android);
          }
        }, 1000);
      } else {
        console.log("请使用移动端登录");
        Toast.fail("请前往移动端下载");
      }
    },
  },
};
</script>
<style scoped>
.cont {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.record {
  width: 100%;
  height: auto;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.record .page1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #26262a;
  z-index: 10;
}
.record pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 500;
}
.record .page1 .dom1 {
  position: absolute;
  width: 60px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
}
.record .page1 .dom1 .logo {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.record .page1 .dom2 {
  position: absolute;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  left: 82px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.record .page1 .dom3 {
  position: absolute;
  width: 72px;
  height: 28px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  color: #26262a;
  text-align: center;
  line-height: 28px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.record .page2 {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 48px;
}
.record .page2 .dom1 {
  width: 100%;
  padding: 25px 20px 0 20px;
  font-size: 20px;
  font-weight: 800;
  color: #26262a;
  line-height: 28px;
  box-sizing: border-box;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: PingFang-SC-Heavy, PingFang-SC;
}
.record .page2 .dom2 {
  position: relative;
  margin: 15px 20px 0 20px;
  height: 54px;
  background: #f7f7fa;
  border-radius: 4px;
}
.record .page2 .dom2 .img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.record .page2 .dom2 .txt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 64px;
  font-size: 16px;
  font-weight: bold;
  color: #26262a;
  line-height: 22px;
}
.record .page2 .dom2 .add {
  position: absolute;
  width: 73px;
  height: 24px;
  background: #f7f7fa;
  border-radius: 2px;
  border: 1px solid #dbdeeb;
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #80869d;
  line-height: 24px;
  right: 14px;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
}
.record .page2 .dom3 {
  position: relative;
  margin: 16px 20px 0 20px;
  font-size: 14px;
  font-weight: 500;
  color: #26262a;
  line-height: 20px;
}
.record .page2 .dom3 pre {
  font-size: 15px;
  font-weight: 500;
  color: #26262a;
  line-height: 20px;
}
.record .page2 .dom4 {
  position: relative;
  margin: 14px 20px 0 20px;
  font-size: 13px;
  font-weight: 500;
  color: #80869d;
  line-height: 17px;
}
.record .page2 .dom4 span:nth-of-type(1) {
  display: inline-block;
  padding-right: 20px;
}
.record .page3 {
  position: relative;
  height: auto;
  margin-bottom: 110px;
}
.record .page3 .box {
  position: relative;
  margin: 33px 20px 0 10px;
  line-height: 1;
  font-size: 0;
}
.record .page3 .box .time {
  position: absolute;
  font-family: Bebas;
}
.record .page3 .box .time > div:nth-of-type(1) {
  font-size: 22px;
  color: #26262a;
  line-height: 29px;
}
.record .page3 .box .time > div:nth-of-type(2) {
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #80869d;
  line-height: 16px;
  margin-top: 2px;
}
.record .page3 .box .tit {
  position: relative;
  margin-left: 75px;
  font-size: 15px;
  font-weight: 500;
  color: #26262a;
  line-height: 21px;
  margin-bottom: 10px;
}
.record .page3 .box .van-image {
  overflow: hidden;
  margin-bottom: 5px;
}
.record .page3 .box .van-image >>> img {
  border-radius: 3px;
  display: block;
}
.record .page3 .box .li_1 {
  width: calc(66.66% - 55px);
  max-height: 300px;
  left: 75px;
  min-height: 100px;
  border-radius: 3px;
}
.record .page3 .box .li_2 {
  width: calc(50% - 40px);
  max-height: 300px;
  left: 75px;
  padding-top: calc(50% - 40px);
  box-sizing: border-box;
  margin-right: 5px;
}
.record .page3 .box .li_2 >>> img {
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.record .page3 .box .li_3,
.record .page3 .box .li_10 {
  width: calc(33.33% - 30px);
  max-height: 300px;
  left: 75px;
  padding-top: calc(33.33% - 30px);
  box-sizing: border-box;
  margin-right: 5px;
}
.record .page3 .box .li_3 >>> img,
.record .page3 .box .li_10 >>> img {
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.record .page3 .box a:nth-of-type(n + 10) {
  display: none;
}
.record .page3 .add {
  position: absolute;
  width: calc(33.33% - 30px);
  max-height: 300px;
  padding-top: calc(33.33% - 30px);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  right: 5px;
  bottom: 5px;
}
.record .page3 .add .add_i {
  padding: 0;
  margin: 0;
  font-size: 23px;
}
.record .page3 .add > div {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
.record .page4 {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  height: 44px;
  background: #26262a;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
  z-index: 10;
}
</style>